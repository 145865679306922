.group-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100%;
    background-color: #ececf0;
}

.active-group-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100%;
    background-color: black;
}

.group-column-text {
    font-size: 6px;
    margin-top: 7px;
    color: #8c96a7;
}

.column-menu {
    position: absolute;
    top: 44px;
    right: 0px;
    z-index: 1000;
    width: 150%;
    background-color: #ececf0;
    box-shadow: 1px 1px 5px grey;
}

.column-menu-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 5px;
}
