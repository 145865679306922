.grid-container {
    background-color: #ececf0;
    display: flex;
    justify-content: center;
    flex-grow: 1;
    height: 70vh;
    width: 80vw;
    flex-direction: column;
    margin-right: 40px;
}

.grid-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.search-bar {
    margin: 15px;
    width: 30vw;
    display: flex;
    flex-direction: row;
    position: relative;
}

.search-bar input {
    background-color: #8c96a7;
    opacity: 0.2;
    height: 10px;
}

.search-bar svg {
    position: absolute;
    top: 25%;
    right: 10px;
}

.filter-container {
    display: flex;
    flex-direction: row;
    margin-right: 10px;
    position: relative;
}

.summary-container {
    /*margin-top: 65px;*/
    flex-direction: row;
    display: flex;
    justify-content: space-between;
}

.summary-container .text-box {
    display: flex;
    flex-direction: column;
    margin-left: 38px;
    padding-top: 4em;
    padding-bottom: 4em;
}

.daily_budget {
    align-items: left;
}

.daily_budget:hover {
    background-color: #fff;
}
