.side-nav {
  position: fixed;
  box-sizing: border-box;
  height: 100%;
  min-height: 100vh;
  min-width: 280px;
  background-color: #131b23;
}
.side-nav .container {
  padding-top: 18px;
  position: relative;
  height: 100%;
}
.side-nav .container .bottom-links {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  margin-bottom: 20px;
}
.side-nav .logo {
  margin-bottom: 8em;
  padding: 4.5px 3em 0px 2em;
}
.side-nav .logo img {
  max-width: 11em;
}
.side-nav ul li a {
  text-transform: uppercase;
  padding: 0.8em 0;
  font-size: 0.8em;
  line-height: 1.5;
  padding-left: 2em;
  text-decoration: none;
  display: block;
  color: #8c96a7;
  cursor: pointer;
}
.side-nav ul li a svg {
  height: 2.5em;
  display: inline-block;
}
.side-nav ul li a svg path {
  fill: #8c96a7;
}
.side-nav ul li a img {
  height: 2.5em;
  display: inline-block;
}
.side-nav ul li a img path {
  fill: #8c96a7;
}
.side-nav ul li a span {
  display: inline-block;
  vertical-align: top;
  padding-top: 0.5em;
  padding-left: 1em;
}
.side-nav ul li a.active {
  background-color: #303743;
  color: #ffffff;
}
.side-nav ul li a.active svg path {
  fill: #5A8A99;
}
.side-nav ul li ul {
  border-top: 0px solid #303743;
}
.side-nav ul li ul li {
  padding-bottom: 0;
  position: relative;
  border-bottom: 0px solid #303743;
}
.side-nav ul li ul li:not(:last-child) {
  border-bottom: 0px solid #303743;
}
.side-nav ul li ul li a {
  padding-left: 3.8em;
  line-height: 2.3;
  font-weight: normal;
  background-color: transparent;
}
.side-nav ul li ul li a.active {
  background-color: #303743;
  border-right-width: 0;
}
.side-nav ul li ul li button {
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
  background-color: transparent;
  min-height: 3.9em;
  cursor: pointer;
}
.side-nav ul li ul li button svg {
  height: 1em;
  fill: #8c96a7;
}
.side-nav ul li ul li button:hover svg {
  fill: #fff;
}
.side-nav ul li ul.min-height {
  height: 200px;
  overflow-y: auto;
}
.side-nav ul li ul::-webkit-scrollbar {
  height: 12px;
  width: 12px;
  background: #131b23;
}
.side-nav ul li ul::-webkit-scrollbar-thumb {
  background: #303743;
  -webkit-border-radius: 1px;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}
.side-nav ul li ul::-webkit-scrollbar-corner {
  background: #000;
}
.side-nav ul li:not(:first-child) {
  border-bottom: 0px solid #303743;
}
